<template>
  <!-- 机票列表 去程 -->
  <SearchUI :query="query" @updateDate="updateDate" @chooseTicket="chooseTicket" :routename="'searchAircraftTo'">
    <!-- 插槽 -->
    <template v-slot:header>
      <h3 class="bigTitle green">
        <span>
          选去程：{{aircraftOrderInfo.from}}
          <van-icon class="iconfont animate__animated" class-prefix="icon" name="tuijianjipiao" />
          {{aircraftOrderInfo.to}}
        </span>
      </h3>
    </template>
  </SearchUI>
</template>

<script>
import { mapGetters } from "vuex";
import SearchUI from "./components/SearchAircraft";
export default {
  components: { SearchUI },
  computed: {
    ...mapGetters(["aircraftOrderInfo"]),
    // 丢给子组件的查询参数
    query: function () {
      let obj = {
        depCityCode: this.aircraftOrderInfo.fromCode,
        arrCityCode: this.aircraftOrderInfo.toCode,
        depDate: this.aircraftOrderInfo.date[0],
        corp: null
      }
      return obj
    }
  },
  methods: {
    // 更新aircraftOrderInfo的时间，把整个aircraftOrderInfo结构都传过去
    updateDate(time) {
      this.$set(this.aircraftOrderInfo.date, 0, time);
      this.$store.dispatch(
        "aircraft/setAircraftOrderInfo",
        this.aircraftOrderInfo
      )
    },
    // 选票（比价在子组件完成了）
    chooseTicket(tickets, query) {
      // 把比价结果放进vuex
      this.$store.dispatch("aircraft/setAircraftTicket", {
        data: tickets,
        index: 0
      });
      this.$router.push({
        path: "/searchAircraftBack",
        query: query
      });
    }
  }
}
</script>

<style scoped>
.bigTitle {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0.4rem;
  background: #e6ffdf;
  text-align: center;
}
</style>

